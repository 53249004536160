import React from "react"
import styles from "../css/postTemplate.module.css"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Carousel3 from '../components/Projects/carousel/carousel3'

export const query = graphql`
 {
  file(relativePath: {eq: "Ilustracionmano.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}

`

const Hechizo = ({ data }) => {
    const author = 'Yukuri'
    const title = 'Hechizo'
    return (
        <Layout>
            <section className={styles.template}>
                <Link to="/projects" className={styles.link}>
                    {" "}
            back to projects
          </Link>
                <div className={styles.info}>
                    <h1>{title}</h1>
                    <h4>
                        <span>by {author}</span>
                    </h4>
                </div>
                <Image fluid={data.file.childImageSharp.fluid} />          <div className={styles.content}>
                    <div>Proyecto final de grado para el curso de Serigrafía artística de la Escuela de Arte de León.
                    Consistió en 16 camisetas y 16 bolsas serigrafiadas a cuatro tintas, con línea, mancha y
      trama en cada una de ellas.</div>

                </div>                    <Carousel3 />

            </section>
        </Layout>
    )
}

export default Hechizo
